<template>
  <div>
    <!-- Main container -->
    <b-container fluid>
      <br />
      <b-card no-body>
        <b-card-header class="d-flex">
          <div class="d-flex">
            <b-img height="40" width="90" class="my-auto" src="img/logo_nkr_2016.svg" alt></b-img>
          </div>

          <div class="d-flex flex-fill">
            <div class="m-auto">
              <h4 class="my-auto mx-2">Spenderverwaltung</h4>
            </div>
          </div>

          <div class="ml-auto">
            <!-- Scan barcode button -->
            <b-button
              class="button-area"
              variant="primary"
              @click="$refs.scanBarcodeModal.show()"
              >{{ trans("main.scan_barcode") }}</b-button
            >

            <!-- Lab import button -->
            <b-button
              class="button-area"
              @click="$bvModal.show('labImport')"
              variant="primary"
              >{{ trans("main.import_lab_results") }}</b-button
            >

            <!-- Import test button -->
            <a target="_blank" href="/importTest"
              ><b-button class="button-area" variant="primary"
                >Import Test</b-button
              ></a
            >

            <!-- Blanko event button -->
            <b-button
              class="button-area"
              @click="
                blankoConsentFormsCount = null;
                showNextBarcodeNumbersInProgress = false;
                $bvModal.show('blankoEvent');
              "
              variant="primary"
              >Blanko-Aktion</b-button
            >

            <!-- Logout button -->
            <b-button class="button-area" href="/logout">{{
              trans("main.logout")
            }}</b-button>
          </div>
        </b-card-header>
        <b-card-body>
          <b-row>
            <!-- Search filter -->
            <b-col lg="4" class="text-left">
              <b-row style="height: 41px">
                <!-- <b-form-group label-cols-sm="3" label="Filter" class="mb-0"> -->
                <b-col cols="12">
                  <b-input-group>
                    <b-form-input
                      v-model="filter"
                      :placeholder="trans('main.search')"
                      @keydown.esc="filter = ''"
                    ></b-form-input>
                    <b-input-group-append>
                      <b-button :disabled="!filter" @click="filter = ''">{{
                        trans("main.reset")
                      }}</b-button>
                    </b-input-group-append>
                  </b-input-group>
                  <!-- </b-form-group> -->
                </b-col>
              </b-row>
            </b-col>

            <!-- Toggle selectors -->
            <b-col lg="6" class="text-left">
              <!-- Per-page selector -->
              <b-button-group>
                <b-button
                  :variant="perPage === 12 ? 'primary' : 'outline-primary'"
                  @click="perPage = 12"
                  >12</b-button
                >
                <b-button
                  :variant="perPage === 50 ? 'primary' : 'outline-primary'"
                  @click="perPage = 50"
                  >50</b-button
                >
                <b-button
                  :variant="perPage === 100 ? 'primary' : 'outline-primary'"
                  @click="perPage = 100"
                  >100</b-button
                >
                <b-button
                  variant="outline-primary"
                  @click="$root.$emit('bv::refresh::table', 'donorTable')"
                >
                  <i class="fas fa-sync-alt"></i>
                </b-button>
              </b-button-group>

              <!-- Approval required checkboxes -->
              <b-form-checkbox-group
                id="approvalCheckboxesGroup"
                class="medicalToggle"
                stacked
              >
                <b-form-checkbox
                  @change="
                    showOnly =
                      showOnly == 'noApprovalRequired'
                        ? null
                        : 'noApprovalRequired';
                    $root.$emit('bv::refresh::table', 'donorTable');
                  "
                  v-model="showOnly"
                  value="noApprovalRequired"
                  >Keine Freigabe nötig</b-form-checkbox
                >
                <b-form-checkbox
                  @change="
                    showOnly =
                      showOnly == 'approvalRequired'
                        ? null
                        : 'approvalRequired';
                    $root.$emit('bv::refresh::table', 'donorTable');
                  "
                  v-model="showOnly"
                  value="approvalRequired"
                  >Freigabe erforderlich</b-form-checkbox
                >
              </b-form-checkbox-group>

              <!-- Filter for status -->
              <b-button-group>
                <b-dropdown
                  :variant="
                    statusFilter == null ? 'outline-primary' : 'primary'
                  "
                  :text="
                    statusFilter == null
                      ? 'Status: Alle, außer Aktiv'
                      : getStatusName(statusFilter)
                  "
                >
                  <b-dropdown-item
                    :disabled="statusFilter == null"
                    @click="
                      statusFilter = null;
                      $root.$emit('bv::refresh::table', 'donorTable');
                    "
                    >Alle, außer Aktiv</b-dropdown-item
                  >
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item
                    :disabled="statusFilter == 0"
                    @click="
                      statusFilter = 0;
                      $root.$emit('bv::refresh::table', 'donorTable');
                    "
                    >Offen</b-dropdown-item
                  >
                  <b-dropdown-item
                    :disabled="statusFilter == 1"
                    @click="
                      statusFilter = 1;
                      $root.$emit('bv::refresh::table', 'donorTable');
                    "
                    >Warten auf Spender</b-dropdown-item
                  >
                  <b-dropdown-item
                    :disabled="statusFilter == 2"
                    @click="
                      statusFilter = 2;
                      $root.$emit('bv::refresh::table', 'donorTable');
                    "
                    >Versandbereit für Labor</b-dropdown-item
                  >
                  <b-dropdown-item
                    :disabled="statusFilter == 3"
                    @click="
                      statusFilter = 3;
                      $root.$emit('bv::refresh::table', 'donorTable');
                    "
                    >Warten auf Labor</b-dropdown-item
                  >
                  <b-dropdown-item
                    :disabled="statusFilter == 4"
                    @click="
                      statusFilter = 4;
                      $root.$emit('bv::refresh::table', 'donorTable');
                    "
                    >Warten auf Übertragung in DoCCom</b-dropdown-item
                  >
                  <b-dropdown-item
                    :disabled="statusFilter == 5"
                    @click="
                      statusFilter = 5;
                      $root.$emit('bv::refresh::table', 'donorTable');
                    "
                    >Übertragen in DoCCom</b-dropdown-item
                  >
                  <b-dropdown-item
                    :disabled="statusFilter == 6"
                    @click="
                      statusFilter = 6;
                      $root.$emit('bv::refresh::table', 'donorTable');
                    "
                    >Aktiv</b-dropdown-item
                  >
                  <b-dropdown-item
                    :disabled="statusFilter == 7"
                    @click="
                      statusFilter = 7;
                      $root.$emit('bv::refresh::table', 'donorTable');
                    "
                    >Warten auf Löschen in DoCCom</b-dropdown-item
                  >
                </b-dropdown>
              </b-button-group>
            </b-col>

            <!-- Clear selected rows button -->
            <b-col lg="2" class="text-right">
              <b-button
                class="button-area"
                variant="outline-primary"
                v-if="selectedRows.length > 0"
                @click="$refs.donorTable.clearSelected()"
                >Auswahl aufheben&nbsp;&cross;</b-button
              >
            </b-col>
          </b-row>
          <br />

          <!-- Main table element -->
          <b-table
            id="donorTable"
            ref="donorTable"
            hover
            selectable
            :select-mode="selectMode"
            selectedVariant="success"
            show-empty
            stacked="md"
            :items="tableDataProvider"
            :fields="fields"
            @row-selected="rowSelected"
            @row-contextmenu="handleRowClick"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            api-url="/api/donor"
          >
            <!-- Templates for empty rows -->
            <template slot="empty">
              <span class="empty-row">{{
                trans("main.no_records_available")
              }}</span>
            </template>
            <template slot="emptyfiltered">
              <span class="empty-row">{{
                trans("main.no_records_found")
              }}</span>
            </template>

            <!-- Barcode -->
            <template slot="cell(barcode)" slot-scope="data"
              >{{ data.item.device_id }}-{{ data.item.barcode_id }}</template
            >

            <!-- Created at -->
            <template slot="cell(created_at)" slot-scope="data">{{
              ("0" + new Date(data.item.created_at).getDate()).slice(-2) +
              "." +
              ("0" + (new Date(data.item.created_at).getMonth() + 1)).slice(
                -2
              ) +
              "." +
              new Date(data.item.created_at).getFullYear()
            }}</template>

            <!-- Sent date -->
            <template slot="cell(sent_date)" slot-scope="data">
              <span
                id="markedSentDate"
                v-if="
                  expirationDateReached(
                    data.item.status,
                    data.item.sent_count,
                    data.item.sent_date
                  )
                "
                class="text-danger"
              >
                <b
                  v-b-tooltip.hover
                  title="Keine Rückmeldung vom Spender erhalten"
                  >{{
                    data.item.sent_date != null
                      ? ("0" + new Date(data.item.sent_date).getDate()).slice(
                          -2
                        ) +
                        "." +
                        (
                          "0" +
                          (new Date(data.item.sent_date).getMonth() + 1)
                        ).slice(-2) +
                        "." +
                        new Date(data.item.sent_date).getFullYear()
                      : ""
                  }}</b
                >
              </span>
              <span v-else>{{
                data.item.sent_date != null
                  ? ("0" + new Date(data.item.sent_date).getDate()).slice(-2) +
                    "." +
                    (
                      "0" +
                      (new Date(data.item.sent_date).getMonth() + 1)
                    ).slice(-2) +
                    "." +
                    new Date(data.item.sent_date).getFullYear()
                  : ""
              }}</span>
            </template>

            <!-- Medical results or medications -->
            <template slot="cell(medicalResultsOrMedications)" slot-scope="row">
              <b-badge
                :id="'showMedicalDetailsButton' + row.item.id"
                :variant="
                  doesThisItemRequireARelease(row.item) ? 'danger' : 'light'
                "
                v-if="
                  (row.item.major_medical_results_affirmed != null &&
                    row.item.major_medical_results_affirmed != '') ||
                  (row.item.medications_affirmed != null &&
                    row.item.medications_affirmed != '') ||
                  row.item.donor_approved == 0
                "
                >Ja&nbsp;&nbsp;</b-badge
              >
              <b-tooltip
                v-if="doesThisItemRequireARelease(row.item)"
                :target="'showMedicalDetailsButton' + row.item.id"
                variant="danger"
                triggers="hover"
                >Freigabe erforderlich</b-tooltip
              >
            </template>

            <!-- Donation -->
            <template slot="cell(donation)" slot-scope="data">{{
              data.item.donation > 0
                ? ((data.item.donation / 100).toFixed(2) + " €").replace(
                    ".",
                    ","
                  )
                : ""
            }}</template>

            <!-- Show details column -->
            <template slot="cell(show_details)" slot-scope="row">
              <div class="show-details-button-container">
                <b-button
                  variant="link"
                  size="sm"
                  @click="row.toggleDetails"
                  :class="
                    row.detailsShowing
                      ? 'show-details-button-active'
                      : 'show-details-button'
                  "
                >
                  <i v-if="row.detailsShowing" class="fas fa-chevron-up"></i>
                  <i v-else class="fas fa-chevron-down"></i>
                </b-button>
              </div>
            </template>

            <!-- Details row -->
            <template v-slot:row-details="row">
              <b-card>
                <!-- Close button X -->
                <!-- <button type="button" class="close" aria-label="Close" @click="row.toggleDetails">
              <span aria-hidden="true">&times;</span>
            </button>
                <br />-->
                <b-row>
                  <b-col cols="4">
                    <b-row>
                      <b-col cols="4" class="text-sm-left">
                        <b>Geschlecht:</b>
                      </b-col>
                      <b-col cols="8">{{
                        row.item.sex == "w" ? "weiblich" : "männlich"
                      }}</b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="4" class="text-sm-left">
                        <b>Geburtsdatum:</b>
                      </b-col>
                      <b-col cols="8">{{
                        ("0" + new Date(row.item.birthdate).getDate()).slice(
                          -2
                        ) +
                        "." +
                        (
                          "0" +
                          (new Date(row.item.birthdate).getMonth() + 1)
                        ).slice(-2) +
                        "." +
                        new Date(row.item.birthdate).getFullYear()
                      }}</b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="4" class="text-sm-left">
                        <b>Straße, Hausnummer:</b>
                      </b-col>
                      <b-col cols="8">{{
                        row.item.street + " " + row.item.street_number
                      }}</b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="4" class="text-sm-left">
                        <b>PLZ, Ort:</b>
                      </b-col>
                      <b-col cols="8">{{
                        row.item.zip_code + " " + row.item.place
                      }}</b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="4" class="column-separator-left">
                    <b-row>
                      <b-col cols="3" class="text-sm-left">
                        <b>Befunde:</b>
                      </b-col>
                      <b-col cols="9">
                        <b-form-radio-group
                          class="medical-radio-button-group inline-block"
                          disabled
                          v-model="row.item.major_medical_results_affirmed"
                          :options="[
                            { text: 'Ja', value: 1 },
                            { text: 'Nein', value: 0 },
                          ]"
                        ></b-form-radio-group>
                        <br />
                        <b-alert
                          class="medicalContentBox medical-alert"
                          v-if="
                            row.item.major_medical_results_affirmed == 1 &&
                            (row.item.major_medical_results == null ||
                              row.item.major_medical_results.trim() == '')
                          "
                          show
                          variant="danger"
                          >Beschreibung erforderlich!</b-alert
                        >
                        <div class="medicalContentBox preserveWhitespace">
                          {{ row.item.major_medical_results }}
                        </div>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="3" class="text-sm-left">
                        <b>Medikamente:</b>
                      </b-col>
                      <b-col cols="9">
                        <b-form-radio-group
                          class="medical-radio-button-group inline-block"
                          disabled
                          v-model="row.item.medications_affirmed"
                          :options="[
                            { text: 'Ja', value: 1 },
                            { text: 'Nein', value: 0 },
                          ]"
                        ></b-form-radio-group>
                        <br />
                        <b-alert
                          class="medicalContentBox medical-alert"
                          v-if="
                            row.item.medications_affirmed == 1 &&
                            (row.item.medications == null ||
                              row.item.medications.trim() == '')
                          "
                          show
                          variant="danger"
                          >Beschreibung erforderlich!</b-alert
                        >
                        <div class="medicalContentBox preserveWhitespace">
                          {{ row.item.medications }}
                        </div>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <b-alert
                          class="medicalContentBox approval-alert"
                          v-if="row.item.donor_approved == 0"
                          show
                          variant="danger"
                          >Freigabe erteilen!</b-alert
                        >
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="4" class="column-separator-left">
                    <b-row>
                      <b-col cols="4" class="text-sm-left">
                        <b>Telefon privat:</b>
                      </b-col>
                      <!-- <b-col sm="2">{{ row.item.phone_private }}</b-col> -->
                      <b-col cols="8">
                        <a :href="'tel:' + row.item.phone_private">{{
                          row.item.phone_private
                        }}</a>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="4" class="text-sm-left">
                        <b>Telefon mobil:</b>
                      </b-col>
                      <!-- <b-col sm="2">{{ row.item.phone_mobile }}</b-col> -->
                      <b-col cols="8">
                        <a :href="'tel:' + row.item.phone_mobile">{{
                          row.item.phone_mobile
                        }}</a>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="4" class="text-sm-left">
                        <b>Telefon dienstlich:</b>
                      </b-col>
                      <!-- <b-col sm="2">{{ row.item.phone_day }}</b-col> -->
                      <b-col cols="8">
                        <a :href="'tel:' + row.item.phone_day">{{
                          row.item.phone_day
                        }}</a>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="4" class="text-sm-left">
                        <b>E-Mail:</b>
                      </b-col>
                      <!-- <b-col sm="2">{{ row.item.phone_day }}</b-col> -->
                      <b-col cols="8">
                        <a
                          :href="
                            'mailto:' +
                            row.item.email +
                            '?Subject=Ihre Registrierung vom ' +
                            (
                              '0' + new Date(row.item.created_at).getDate()
                            ).slice(-2) +
                            '.' +
                            (
                              '0' +
                              (new Date(row.item.created_at).getMonth() + 1)
                            ).slice(-2) +
                            '.' +
                            new Date(row.item.created_at).getFullYear() +
                            '&body=Sehr geehrte' +
                            (row.item.sex == 'w' ? ' Frau ' : 'r Herr ') +
                            row.item.lastname +
                            ','
                          "
                          target="_top"
                          >{{ row.item.email }}</a
                        >
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row class="text-center" style="display: inline">
                  <div>
                    <b-button variant="primary" @click="editData(row)"
                      >Bearbeiten</b-button
                    >
                  </div>
                </b-row>
              </b-card>
            </template>

            <!-- Template for selected rows -->
            <template slot="cell(selected)" slot-scope="{ rowSelected }">
              <span v-if="rowSelected">✔</span>
            </template>

            <!-- Template for the visualization of the different statuses -->
            <template slot="cell(status)" slot-scope="row">
              <b-badge v-if="row.value == 0" variant="light">{{
                trans("main.open")
              }}</b-badge>
              <b-badge v-if="row.value == 1" variant="secondary">{{
                trans("main.waiting_for_donor")
              }}</b-badge>
              <b-badge v-if="row.value == 2" variant="primary">{{
                trans("main.ready_for_sending_to_lab")
              }}</b-badge>
              <b-badge v-if="row.value == 3" variant="info">{{
                trans("main.waiting_for_lab")
              }}</b-badge>
              <b-badge v-if="row.value == 4" variant="warning">{{
                trans("main.waiting_for_transferring_to_doccom")
              }}</b-badge>
              <b-badge
                v-if="row.value == 5"
                style="color: black; background-color: #7ff3ad"
                >{{ trans("main.transferred_in_doccom") }}</b-badge
              >
              <b-badge v-if="row.value == 6" variant="success">{{
                trans("main.active")
              }}</b-badge>
              <b-badge v-if="row.value == 7" variant="danger">{{
                trans("main.waiting_for_deletion_in_doccom")
              }}</b-badge>
              <span v-if="row.item.sample_was_not_evaluable">
                <i
                  :id="'labIcon' + row.item.id"
                  class="fas fa-vial fa-lg text-danger lab-icon"
                ></i>
                <b-tooltip
                  class="danger-tooltip"
                  :target="'labIcon' + row.item.id"
                  variant="danger"
                  triggers="hover"
                  >Letzte Probe war nicht auswertbar</b-tooltip
                >
              </span>
            </template>
          </b-table>

          <!-- Pagination -->
          <b-row>
            <b-col md="6" class="my-1">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                class="my-0"
              ></b-pagination>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
      <br />
    </b-container>

    <!-- Scan barcode modal -->
    <scanBarcode
      ref="scanBarcodeModal"
      @donorRegistered="$refs.scanBarcodeModal.show()"
    ></scanBarcode>

    <!-- Lab import modal -->
    <b-modal
      id="labImport"
      :title="trans('main.import_lab_results')"
      hide-footer
    >
      <!-- Dropzone -->
      <vue-dropzone
        id="dropzone"
        ref="labResultsDropzone"
        :options="dropzoneOptions"
        @vdropzone-file-added="dropzoneFileListChanged"
        @vdropzone-removed-file="dropzoneFileListChanged"
        @vdropzone-sending="showLabImportProgress"
        @vdropzone-complete="labResultsUploadInProgress = false"
        @vdropzone-success="showLabImportSuccess"
        @vdropzone-error="showLabImportError"
      ></vue-dropzone>

      <!-- Modal footer buttons -->
      <div style="text-align: right">
        <!-- Import button -->
        <b-button
          v-bind:disabled="labImportButtonIsDisabled"
          class="mt-3"
          variant="primary"
          @click="startUploadLabFiles"
        >
          <!-- Text -->
          {{ labImportButtonText }}
          <!-- Spinner (only visible, if upload in progress) -->
          <span v-if="labResultsUploadInProgress">
            &nbsp;
            <b-spinner small></b-spinner>
          </span>
        </b-button>

        <!-- Cancel button -->
        <b-button
          v-bind:disabled="labResultsUploadInProgress"
          class="mt-3"
          @click="$bvModal.hide('labImport')"
          >{{ trans("main.cancel") }}</b-button
        >
      </div>
    </b-modal>

    <!-- Blanko event modal -->
    <b-modal
      id="blankoEvent"
      title="Blanko-Aktion"
      @shown="focusBlankoInput"
      hide-footer
    >
      <b-form-input
        ref="blankoInput"
        v-model.number="blankoConsentFormsCount"
        placeholder="Anzahl benötigter EVEs"
        type="number"
        step="1"
        min="0"
        max="9999999999"
        @keydown.enter="showNextBlankoNumbers(blankoConsentFormsCount)"
      ></b-form-input>
      <!-- Modal footer buttons -->
      <div style="text-align: right">
        <!-- Generate consent forms button -->
        <b-button
          :disabled="
            blankoConsentFormsCount % 1 != 0 ||
            blankoConsentFormsCount < 1 ||
            blankoConsentFormsCount > 9999999999 ||
            showNextBarcodeNumbersInProgress
          "
          class="mt-3"
          variant="primary"
          @click="showNextBlankoNumbers(blankoConsentFormsCount)"
        >
          <!-- Text -->
          {{ blankoConsentFormsCount }} EVE(s) generieren
          <!-- Spinner (only visible, if upload in progress) -->
          <span v-if="showNextBarcodeNumbersInProgress">
            &nbsp;
            <b-spinner small></b-spinner>
          </span>
        </b-button>
        <!-- Cancel button -->
        <b-button class="mt-3" @click="$bvModal.hide('blankoEvent')">{{
          trans("main.cancel")
        }}</b-button>
      </div>
    </b-modal>

    <!-- Edit medical details modal -->
    <b-modal
      id="editData"
      title="Spenderdaten bearbeiten"
      size="xl"
      hide-footer
      @shown="editDataModalSaveButtonIsDisabled = true"
    >
      <b-row v-if="editDataModalData" class="editDataModalMainRow">
        <b-col cols="7">
          <b-row>
            <b-col sm="4">
              <label>Spendernummer:</label>
            </b-col>
            <b-col sm="8">
              <b-form-input
                v-model="editDataModalData.donor_id"
                @input="editDataModalDataChanged()"
              ></b-form-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="4">
              <label>Vorname:</label>
            </b-col>
            <b-col sm="8">
              <b-form-input
                v-model="editDataModalData.firstname"
                @input="editDataModalDataChanged()"
              ></b-form-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="4">
              <label>Nachname:</label>
            </b-col>
            <b-col sm="8">
              <b-form-input
                v-model="editDataModalData.lastname"
                @input="editDataModalDataChanged()"
              ></b-form-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="4">
              <label>Geschlecht:</label>
            </b-col>
            <b-col sm="8">
              <b-form-radio-group
                v-model="editDataModalData.sex"
                :options="[
                  { text: 'weiblich', value: 'w' },
                  { text: 'männlich', value: 'm' },
                ]"
                @input="editDataModalDataChanged()"
              ></b-form-radio-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="4">
              <label>Geburtsdatum:</label>
            </b-col>
            <b-col sm="8">
              <date-picker
                :config="editDataModalDatePickerOptions"
                placeholder="tt.mm.jjjj"
                v-model="editDataModalBirthdate"
                @dp-change="editDataModalDataChanged"
              ></date-picker>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="4">
              <label>Straße:</label>
            </b-col>
            <b-col sm="8">
              <b-form-input
                v-model="editDataModalData.street"
                @input="editDataModalDataChanged"
              ></b-form-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="4">
              <label>Hausnummer:</label>
            </b-col>
            <b-col sm="8">
              <b-form-input
                v-model="editDataModalData.street_number"
                @input="editDataModalDataChanged"
              ></b-form-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="4">
              <label>PLZ:</label>
            </b-col>
            <b-col sm="8">
              <b-form-input
                v-model="editDataModalData.zip_code"
                @input="editDataModalDataChanged"
              ></b-form-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="4">
              <label>Ort:</label>
            </b-col>
            <b-col sm="8">
              <b-form-input
                v-model="editDataModalData.place"
                @input="editDataModalDataChanged"
              ></b-form-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="4">
              <label>Telefon privat:</label>
            </b-col>
            <b-col sm="8">
              <b-form-input
                v-model="editDataModalData.phone_private"
                @input="editDataModalDataChanged"
              ></b-form-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="4">
              <label>Telefon mobil:</label>
            </b-col>
            <b-col sm="8">
              <b-form-input
                v-model="editDataModalData.phone_mobile"
                @input="editDataModalDataChanged"
              ></b-form-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="4">
              <label>Telefon dienstlich:</label>
            </b-col>
            <b-col sm="8">
              <b-form-input
                v-model="editDataModalData.phone_day"
                @input="editDataModalDataChanged"
              ></b-form-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="4">
              <label>E-Mail:</label>
            </b-col>
            <b-col sm="8">
              <b-form-input
                v-model="editDataModalData.email"
                @input="editDataModalDataChanged"
              ></b-form-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="4">
              <label>Spende:</label>
            </b-col>
            <b-col sm="8">
              <b-input-group append="€">
                <b-input
                  v-model="editDataModalDonation"
                  @input="editDataModalDataChanged"
                ></b-input>
              </b-input-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="5">
          <b-form-group>
            <b>Befunde:&nbsp;&nbsp;</b>
            <b-form-radio-group
              id="medical_results_radio_buttons"
              class="inline-block"
              v-model="editDataModalData.major_medical_results_affirmed"
              :options="[
                { text: 'Ja', value: 1 },
                { text: 'Nein', value: 0 },
              ]"
              @input="editDataModalDataChanged()"
              :disabled="
                editDataModalData.major_medical_results != null &&
                editDataModalData.major_medical_results.trim() != ''
              "
            ></b-form-radio-group>
            <b-tooltip
              v-if="
                editDataModalData.major_medical_results != null &&
                editDataModalData.major_medical_results.trim() != ''
              "
              target="medical_results_radio_buttons"
              data-placement="right"
              >Löschen Sie zum Umschalten den Beschreibungstext!</b-tooltip
            >
            <b-form-textarea
              class="textarea-with-validation"
              v-model="editDataModalData.major_medical_results"
              rows="7"
              max-rows="7"
              @update="editDataModalDataChanged()"
              :disabled="!editDataModalData.major_medical_results_affirmed"
              :state="
                editDataModalData.major_medical_results_affirmed == 0 ||
                (editDataModalData.major_medical_results != null &&
                  editDataModalData.major_medical_results.trim() != '')
              "
              aria-describedby="major_medical_results-feedback"
            ></b-form-textarea>
            <b-form-invalid-feedback id="major_medical_results-feedback"
              >Beschreibung erforderlich</b-form-invalid-feedback
            >
          </b-form-group>

          <br />

          <b-form-group>
            <b>Medikamente:&nbsp;&nbsp;</b>
            <b-form-radio-group
              id="medications_radio_buttons"
              class="inline-block"
              v-model="editDataModalData.medications_affirmed"
              :options="[
                { text: 'Ja', value: 1 },
                { text: 'Nein', value: 0 },
              ]"
              @input="editDataModalDataChanged()"
              :disabled="
                editDataModalData.medications != null &&
                editDataModalData.medications.trim() != ''
              "
            ></b-form-radio-group>
            <b-tooltip
              v-if="
                editDataModalData.medications != null &&
                editDataModalData.medications.trim() != ''
              "
              target="medications_radio_buttons"
              data-placement="right"
              >Löschen Sie zum Umschalten den Beschreibungstext!</b-tooltip
            >
            <b-form-textarea
              class="textarea-with-validation"
              v-model="editDataModalData.medications"
              rows="7"
              max-rows="7"
              @update="editDataModalDataChanged()"
              :disabled="!editDataModalData.medications_affirmed"
              :state="
                editDataModalData.medications_affirmed == 0 ||
                (editDataModalData.medications != null &&
                  editDataModalData.medications.trim() != '')
              "
              aria-describedby="medications-feedback"
            ></b-form-textarea>
            <b-form-invalid-feedback id="medications-feedback"
              >Beschreibung erforderlich</b-form-invalid-feedback
            >
          </b-form-group>

          <br />

          <b-form-group>
            <b>Freigeben?:&nbsp;&nbsp;</b>
            <b-form-radio-group
              :disabled="
                !editDataModalData.major_medical_results_affirmed &&
                !editDataModalData.medications_affirmed
              "
              id="donor_approved_radio_buttons"
              class="inline-block"
              v-model="editDataModalData.donor_approved"
              :options="[
                { text: 'Ja', value: 1 },
                { text: 'Nein', value: 0 },
              ]"
              @input="editDataModalDataChanged()"
            ></b-form-radio-group>
          </b-form-group>

          <b-form-group v-model="editDataModalShowErrorTextArea.error">
            <b-alert
              :show="editDataModalShowErrorTextArea.error"
              variant="danger"
              v-for="value in editDataModalErrors"
              :key="value.id"
              >{{ value[0] }}</b-alert
            >
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Modal footer buttons -->
      <div style="text-align: right">
        <b-button
          class="mt-3"
          variant="primary"
          @click="saveDonorDetails"
          :disabled="editDataModalSaveButtonIsDisabled"
        >
          <!-- Text -->
          Speichern
          <!-- Spinner (only visible, if upload in progress) -->
          <span v-if="editDataModalSaveDetailsIsInProgress">
            &nbsp;
            <b-spinner small></b-spinner>
          </span>
        </b-button>
        <b-button class="mt-3" @click="$bvModal.hide('editData')">{{
          trans("main.cancel")
        }}</b-button>
      </div>
    </b-modal>

    <!-- Context menu -->
    <vue-simple-context-menu
      :elementId="'contextMenu'"
      :ref="'vueSimpleContextMenu'"
      :options="contextMenuOptions"
      @option-clicked="contextMenuOptionClicked"
    ></vue-simple-context-menu>
  </div>
</template>

<script>
import VueSimpleContextMenu from "vue-simple-context-menu";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import scanBarcode from "./scanBarcode";
export default {
  data() {
    return {
      // Lab import modal data.
      labImportButtonIsDisabled: true,
      labImportButtonText: i18n.main.import,
      labResultsUploadInProgress: false,
      refreshIntervalId: null,

      // Dropzone data.
      dropzoneOptions: {
        url: "/api/uploadLabResults",
        headers: {
          "X-CSRF-TOKEN": document.head.querySelector('meta[name="csrf-token"]')
            .content,
          "X-Requested-With": "XMLHttpRequest",
          Authorization: "Bearer " + window.apiToken,
        },
        maxFiles: 1,
        maxFilesize: 0.5,
        timeout: 5 * 60 * 1000,
        acceptedFiles: ".txt",
        addRemoveLinks: true,
        dictDefaultMessage:
          i18n.main.drop_lab_results_file_here_or_click_to_upload,
        dictRemoveFile: i18n.main.remove_file,
        dictCancelUpload: i18n.main.cancel_upload,
        dictInvalidFileType: i18n.main.you_cannot_upload_files_with_this_type,
        autoProcessQueue: false,
        autoQueue: false,
      },

      // Blako event modal.
      blankoConsentFormsCount: null,
      showNextBarcodeNumbersInProgress: false,

      // Edit data modal data.
      editDataModalRowItem: null,
      editDataModalSaveButtonIsDisabled: true,
      editDataModalSaveDetailsIsInProgress: false,
      editDataModalBirthdate: null,
      editDataModalDonation: null,
      editDataModalData: null,
      editDataModalDatePickerOptions: {
        locale: "de",
        format: "DD.MM.YYYY",
        useCurrent: false,
        showClear: true,
        showClose: true,
        widgetPositioning: {
          horizontal: "right",
          vertical: "auto",
        },
      },
      editDataModalShowErrorTextArea: {
        error: "",
      },
      editDataModalErrors: [],

      // Status filter data.
      statusFilterText: "Status: Alle",

      // Table data.
      sortBy: "barcode",
      sortDirection: "asc",
      sortDesc: false,
      selectMode: "range",
      selectedRows: [],
      showOnly: null,
      statusFilter: null,
      perPage: 12,
      currentPage: 1,
      totalRows: 0,
      filter: null,
      fields: [
        { key: "barcode", label: "Barcode-Nr.", sortable: true },
        {
          key: "donor_id",
          label: i18n.main.donor_id,
          sortable: true,
        },
        {
          key: "created_at",
          label: i18n.main.created_at,
          sortable: true,
        },
        {
          key: "firstname",
          label: i18n.main.firstname,
          sortable: true,
        },
        {
          key: "lastname",
          label: i18n.main.lastname,
          sortable: true,
        },
        {
          key: "status",
          label: i18n.main.status,
          sortable: true,
        },
        {
          key: "sent_count",
          label: i18n.main.sent_count,
          sortable: true,
          class: "text-center",
        },
        {
          key: "sent_date",
          label: i18n.main.sent_date,
          sortable: true,
        },
        {
          key: "medicalResultsOrMedications",
          label: "Befunde/Medikamente",
          sortable: true,
          class: "text-center",
        },
        {
          key: "donation",
          label: "Spende",
          sortable: true,
          class: "text-right",
        },
        {
          key: "selected",
          label: i18n.main.selection,
          class: "text-center",
        },
        {
          key: "show_details",
          label: "",
        },
      ],

      // Context menu data.
      contextMenuOptions: [],
    };
  },
  methods: {
    showLabImportProgress() {
      this.labResultsUploadInProgress = true;
      this.labImportButtonIsDisabled = true;
      let self = this;

      this.refreshIntervalId = setInterval(function () {
        axios
          .get("/labImportProgress")
          .then((response) => {
            if (response.data == "finished") {
              self.resetLabImportButton();
            } else {
              self.labImportButtonText = "Verarbeite " + response.data;
            }
          })
          .catch(function (error) {
            Swal.fire({
              type: "error",
              title: i18n.main.error,
              text: error + ": " + error.response.data,
            });
          });
      }, 1000);
    },
    async resetLabImportButton() {
      /* Try to reset the button text three times.
       * In the event that errors occur during the import,
       * this ensures that the button gets the correct text again.
       */
      for (let i = 1; i <= 3; i++) {
        setTimeout(() => {
          console.log(
            "Resetting lab import button after " + i * 1000 + "ms..."
          );

          // Stop checking import progress.
          clearInterval(this.refreshIntervalId);

          // Reset lab import button and status flags.
          this.labImportButtonIsDisabled = true;
          this.labResultsUploadInProgress = false;
          this.labImportButtonText = "Importieren";
        }, i * 1000);
      }
    },
    showLabImportSuccess(response) {
      Swal.fire({
        type: "success",
        title: response.xhr.responseText,
        showConfirmButton: false,
        timer: 4000,
      });

      // Close the dropzone modal.
      this.$bvModal.hide("labImport");

      // Check dropzone file list (if is empty, the import button will set enabled here).
      this.dropzoneFileListChanged();

      // Refresh table.
      this.$root.$emit("bv::refresh::table", "donorTable");
    },
    showLabImportError(response) {
      // Define a short link to the dropzone.
      var dz = this.$refs.labResultsDropzone.dropzone;

      // Set alert defaults.
      var errorType = "error";
      var errorTitle = i18n.main.error;
      var errortext = i18n.main.unknown_error;

      // If XMLHttpRequest is available...
      if (response.xhr != undefined && response.xhr.responseText != "") {
        // ... Get and handle HTTP statuscode.
        var httpStatusCode = response.xhr.status;
        switch (httpStatusCode) {
          case 406: // = Not Accepted
            errorTitle = i18n.main.not_accepted;
            errortext = response.xhr.responseText;
            break;
          case 409: // = Conflict
            errorTitle = i18n.main.error;
            errortext = response.xhr.responseText;
            break;
          default:
            errorTitle = response.xhr.statusText;
            errortext = response.xhr.responseText;
            break;
        }

        // Refresh table.
        this.$root.$emit("bv::refresh::table", "donorTable");

        this.resetLabImportButton();
      }
      // Else, if more than 1 file has been dragged to the dropzone...
      else if (dz.files.length > 1) {
        // ... Set alert text.
        errorType = "info";
        errorTitle = i18n.main.note;
        errortext = i18n.main.you_may_only_import_one_file;
      }
      // Else, if the user has canceled the upload...
      else if (response.status != undefined && response.status === "canceled") {
        // ... Set alert text.
        errorType = "info";
        errorTitle = i18n.main.note;
        errortext = i18n.main.upload_canceled;
      }
      // Else, if an other error was provided by the dropzone...
      else if (
        response.previewElement.innerText != undefined &&
        response.previewElement.innerText != ""
      ) {
        // ... Try to extract filename and message from the dropzone error element.
        var innerTextLines = response.previewTemplate.innerText.split("\n");
        var filename = innerTextLines[1];
        var message = innerTextLines[2];

        // ... Build error text.
        if (innerTextLines.length > 1) {
          errortext = i18n.main.file + " '" + filename + "': " + message;
        } else {
          // Splitting 'innerTextLines' failed. Use the single line for error text.
          errortext = innerTextLines[0];
        }

        // ... Remove all files from dropzone's file list.
        dz.files.forEach((file) => dz.removeFile(file));
      }
      // Else...
      else {
        // ... Set error text to an unknown error.
        errortext = i18n.main.unknown_error;
      }

      // Fire alert.
      Swal.fire({
        type: errorType,
        title: errorTitle,
        text: errortext,
      });

      // Remove error files from dropzone's file list.
      dz.files.forEach((file) => {
        if (file.status === "error") {
          dz.removeFile(file);
        }
      });
    },
    dropzoneFileListChanged() {
      // Define a short link to the dropzone.
      var dz = this.$refs.labResultsDropzone.dropzone;

      // If upload is in progress...
      if (this.labResultsUploadInProgress) {
        dz.files.forEach((file) => {
          // ... and files are marked with status 'added'...
          if (file.status === "added") {
            // ... the user is probably trying to add another file.
            // Remove the file.
            dz.removeFile(file);
          }
        });
      }
      // If upload is not in progress...
      else {
        // Set lab import button disabled depending on file list length.
        if (dz.files.length == 0) {
          this.labImportButtonIsDisabled = true;
        } else {
          this.labImportButtonIsDisabled = false;
        }
      }
    },
    tableDataProvider(ctx) {
      // Define a promise.
      // --> Note: Read section 'Using a Promise to return data' here: https://bootstrap-vue.js.org/docs/components/table/#using-items-provider-functions

      ctx.showOnly = this.showOnly;
      ctx.statusFilter = this.statusFilter;

      const promise = axios.get(ctx.apiUrl, { params: ctx });

      // Must return a promise that resolves to an array of items
      return promise.then((data) => {
        const items = data.data.data;
        this.totalRows = data.data.total;
        return items || [];
      });
    },
    rowSelected(selectedItems) {
      this.selectedRows = selectedItems;
    },
    handleRowClick(item, index, event) {
      // Prevent showing right-click-menu from browser.
      event.preventDefault();

      var selectedRowsCount = this.selectedRows.length;
      var rightClickedRowIsSelected = this.selectedRows.some(
        (e) => e.id === item.id
      );

      if (selectedRowsCount == 0) {
        // No rows are selected.
        // Select right clicked row.
        this.selectedRows.push(item);
        this.$refs.donorTable.selectRow(index);
      } else if (!rightClickedRowIsSelected) {
        // Rows are still selected but this click was on an unselected row.
        // Clear selection of all other rows and select right clicked row only.
        this.selectedRows = [];
        this.selectedRows.push(item);
        this.$refs.donorTable.clearSelected();
        this.$refs.donorTable.selectRow(index);
      }

      // Define Status enum.
      var Status = {
        0: "open",
        1: "waitingForDonor",
        2: "readyForSend",
        3: "waitingForLab",
        4: "waitingForTransfer",
        5: "transferred",
        6: "active",
        7: "waitingForDeletionInDoccom",
      };

      // Define helper variables.
      var allSelectedDoesNotRequireARelease = true;
      var currentStatus = -1;

      // Check if all selected donors are in the same status.
      if (this.selectedRows.length > 0) {
        // Get status from first selected row.
        currentStatus = this.selectedRows[0].status;

        // Check, if other selected rows has the same status.
        for (let i = 1; i < this.selectedRows.length; i++) {
          var currentRow = this.selectedRows[i];

          // If not...
          if (currentRow.status != currentStatus) {
            // ... set 'currentStatus' to an invalid value and break the loop.
            currentStatus = -1;
            break;
          }
        }
      }

      // Check if all selected donors doesn't require a release.
      if (this.selectedRows.length > 0) {
        for (let i = 0; i < this.selectedRows.length; i++) {
          var currentRow = this.selectedRows[i];

          if (this.doesThisItemRequireARelease(currentRow) == true) {
            allSelectedDoesNotRequireARelease = false;
          }
        }
      }

      // Build menu options.
      this.contextMenuOptions = [
        { name: i18n.main.generate_EVE, value: "generate_PDF" },
      ];
      switch (Status[currentStatus]) {
        case "open":
          if (allSelectedDoesNotRequireARelease) {
            this.contextMenuOptions.push({
              name: i18n.main.sent_to_donor,
              value: "sentToDonor",
            });
          }
          break;
        case "waitingForDonor":
          this.contextMenuOptions.push({
            name: i18n.main.agreement_and_test_received,
            value: "agreementAndTestReceived",
          });
          this.contextMenuOptions.push({
            name: 'Status zurück zu "Offen"',
            value: "backToOpen",
          });
          break;
        case "readyForSend":
          this.contextMenuOptions.push({
            name: i18n.main.sent_to_lab,
            value: "sentToLab",
          });
          this.contextMenuOptions.push({
            name: 'Status zurück zu "Offen"',
            value: "backToOpen",
          });
          break;
        case "waitingForLab":
          this.contextMenuOptions.push({
            name: 'Status zurück zu "Offen"',
            value: "backToOpen",
          });
          this.contextMenuOptions.push({
            name: "Probe nicht auswertbar",
            value: "sampleWasNotEvaluable",
          });
          break;
        case "transferred":
          this.contextMenuOptions.push({
            name: 'Spender auf "Aktiv" setzen',
            value: "setActive",
          });
          break;
      }

      if (currentStatus > -1 && currentStatus >= 5) {
        this.contextMenuOptions.push({
          name: i18n.main.generate_donor_card,
          value: "generate_donor_card",
        });
      }

      if (currentStatus > -1 && currentStatus < 7) {
        this.contextMenuOptions.push({
          name: "Löschen",
          value: "deleteDonor",
        });
      }

      // Show the menu.
      this.$refs.vueSimpleContextMenu.showMenu(event, this.selectedRows);
    },
    contextMenuOptionClicked(event) {
      // Collect ids of selected items
      var selectedIDs = [];
      for (let i = 0; i < this.selectedRows.length; i++) {
        selectedIDs.push(this.selectedRows[i].id);
      }

      // Process clicked option
      switch (event.option.value) {
        case "generate_PDF":
          var pdfUrl =
            "/donorPdf?type=donorConsentForm&selectedIDs=" +
            JSON.stringify(selectedIDs);
          window.open(pdfUrl, "_blank");
          break;
        case "generate_donor_card":
          var pdfUrl =
            "/donorPdf?type=donorCard&selectedIDs=" +
            JSON.stringify(selectedIDs);
          window.open(pdfUrl, "_blank");
          break;
        case "sentToDonor":
        case "agreementAndTestReceived":
        case "sentToLab":
        case "backToOpen":
        case "sampleWasNotEvaluable":
        case "setActive":
          this.runTransition(selectedIDs, event.option.value);
          break;
        case "deleteDonor":
          Swal.fire({
            title: "Spender löschen?",
            text:
              selectedIDs.length == 1
                ? "Möchten Sie diesen Spender wirklich löschen? Dies kann nicht rückgängig gemacht werden!"
                : "Möchten Sie diese " +
                  selectedIDs.length +
                  " Spender wirklich löschen? Dies kann nicht rückgängig gemacht werden!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ja, Spender löschen",
            cancelButtonText: "Abbrechen",
          }).then((result) => {
            if (result.value) {
              this.deleteDonors(selectedIDs);
            }
          });
      }
    },
    runTransition(selectedIDs, transitionName) {
      axios
        .get("/runTransition", {
          params: {
            selectedIDs: JSON.stringify(selectedIDs),
            transition: transitionName,
          },
        })
        .then((response) => {
          // Refresh table.
          this.$root.$emit("bv::refresh::table", "donorTable");
        })
        .catch(function (error) {
          Swal.fire({
            type: "error",
            title: i18n.main.error,
            text: error + ": " + error.response.data,
          });
        });
    },
    startUploadLabFiles() {
      // Enqueue selected files in the waiting queue.
      try {
        this.$refs.labResultsDropzone.dropzone
          .getAcceptedFiles()
          .forEach((f) => {
            if (f.status != "queued") {
              this.$refs.labResultsDropzone.dropzone.enqueueFile(f);
            }
          });
      } catch (error) {
        Swal.fire({
          type: "error",
          title: i18n.main.error,
          text: error,
        });
      }
      // Start uploading files.
      this.$refs.labResultsDropzone.dropzone.processQueue();
    },
    focusBlankoInput() {
      this.$refs["blankoInput"].focus();
    },
    showNextBlankoNumbers(count) {
      let self = this;
      this.showNextBarcodeNumbersInProgress = true;
      axios
        .get("/api/getNextBlankoNumbers/" + count, {})
        .then(function (response) {
          self.showNextBarcodeNumbersInProgress = false;
          Swal.fire({
            title:
              count == 1
                ? count + " EVE generieren?"
                : count + " EVEs generieren?",
            text:
              count == 1
                ? 'Dadurch wird die Barcodenummer "1-' +
                  response.data[0] +
                  '" vergeben! Dies kann nicht rückgängig gemacht werden!'
                : 'Dadurch werden die Barcodenummern "1-' +
                  response.data[0] +
                  '" bis "1-' +
                  response.data[1] +
                  '" vergeben! Dies kann nicht rückgängig gemacht werden!',
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText:
              count == 1 ? "Ja, EVE generieren" : "Ja, EVEs generieren",
            cancelButtonText: "Abbrechen",
          }).then((result) => {
            self.showNextBarcodeNumbersInProgress = false;
            if (result.value) {
              var pdfUrl = "/generateBlankoPDF/" + self.blankoConsentFormsCount;
              window.open(pdfUrl, "_blank");
              self.$bvModal.hide("blankoEvent");
            }
          });
        })
        .catch(function (error) {
          self.showNextBarcodeNumbersInProgress = false;
          Swal.fire({
            type: "error",
            title: i18n.main.error,
            text: error + ": " + error,
          });
        });
    },
    expirationDateReached(status, sent_count, sent_date) {
      var millisecondsPerMinute = 60000;
      var sentDate = new Date(sent_date);
      var terminationDate = new Date(
        Date.now() - 10 * 24 * 60 * millisecondsPerMinute
      );
      return status == 1 && sent_count >= 3 && sentDate <= terminationDate;
    },
    editData(row) {
      // Clone row item to pass it by value.
      this.editDataModalRowItem = row.item;
      this.editDataModalData = JSON.parse(JSON.stringify(row.item));
      this.editDataModalBirthdate =
        ("0" + new Date(row.item.birthdate).getDate()).slice(-2) +
        "." +
        ("0" + (new Date(row.item.birthdate).getMonth() + 1)).slice(-2) +
        "." +
        new Date(row.item.birthdate).getFullYear();
      this.editDataModalDonation =
        row.item.donation > 0
          ? (row.item.donation / 100).toFixed(2).replace(".", ",")
          : "";
      this.editDataModalSaveButtonIsDisabled = true;
      this.editDataModalSaveDetailsIsInProgress = false;
      this.editDataModalShowErrorTextArea.error = false;
      this.editDataModalErrors.error = "";
      this.$bvModal.show("editData");
    },
    doesThisItemRequireARelease(item) {
      // Check if release for major medical results is required.
      // if (item.major_medical_results_affirmed == 1) {
      //   if (
      //     item.major_medical_results == null ||
      //     item.major_medical_results.trim() == ""
      //   ) {
      //     return true;
      //   }
      // }

      // // Check if release for medications is required.
      // if (item.medications_affirmed == 1) {
      //   if (item.medications == null || item.medications.trim() == "") {
      //     return true;
      //   }
      // }
      if (item.donor_approved == 0) {
        return true;
      }

      return false;
    },
    moment: function () {
      return moment();
    },
    editDataModalDataChanged() {
      // Transform birthdate to database format.
      var birthdateForDatabase = moment(
        this.editDataModalBirthdate,
        "DD.MM.YYYY"
      )
        .utc("+0200")
        .format("YYYY-MM-DD HH:mm:ss");
      this.editDataModalData.birthdate = birthdateForDatabase;

      // Transform donation to database format.
      var donationForDatabase = this.editDataModalDonation.replace(",", "");
      this.editDataModalData.donation = donationForDatabase;

      this.editDataModalSaveButtonIsDisabled = false;
    },
    saveDonorDetails() {
      this.editDataModalSaveButtonIsDisabled = true;
      this.editDataModalSaveDetailsIsInProgress = true;
      let self = this;
      axios
        .post("/api/saveDonorDetails", self.editDataModalData)
        .then(function (response) {
          self.editDataModalRowItem.status = response.data.status;
          self.editDataModalRowItem.donor_id = response.data.donor_id;
          self.editDataModalRowItem.firstname = response.data.firstname;
          self.editDataModalRowItem.lastname = response.data.lastname;
          self.editDataModalRowItem.sex = response.data.sex;
          self.editDataModalRowItem.birthdate = response.data.birthdate;
          self.editDataModalRowItem.street = response.data.street;
          self.editDataModalRowItem.street_number = response.data.street_number;
          self.editDataModalRowItem.zip_code = response.data.zip_code;
          self.editDataModalRowItem.place = response.data.place;
          self.editDataModalRowItem.phone_private = response.data.phone_private;
          self.editDataModalRowItem.phone_mobile = response.data.phone_mobile;
          self.editDataModalRowItem.phone_day = response.data.phone_day;
          self.editDataModalRowItem.email = response.data.email;
          self.editDataModalRowItem.donation = response.data.donation;
          self.editDataModalRowItem.major_medical_results_affirmed =
            response.data.major_medical_results_affirmed;
          self.editDataModalRowItem.major_medical_results =
            response.data.major_medical_results;
          self.editDataModalRowItem.medications_affirmed =
            response.data.medications_affirmed;
          self.editDataModalRowItem.medications = response.data.medications;
          self.editDataModalRowItem.donor_approved =
            response.data.donor_approved;
          self.editDataModalShowErrorTextArea.error = false;
          self.resetAndCloseMedicalModal();
        })
        .catch((err) => {
          this.editDataModalSaveDetailsIsInProgress = false;
          this.editDataModalSaveButtonIsDisabled = false;
          if (err.response != null && err.response.data.errors != undefined) {
            this.editDataModalShowErrorTextArea.error = true;
            this.editDataModalErrors = err.response.data.errors;
          } else {
            Swal.fire({
              type: "error",
              title: i18n.main.error,
              text: err,
            });
          }
        });
    },
    deleteDonors(selectedIDs) {
      let self = this;
      axios
        .post("/api/deleteDonors", {
          selectedIDs: JSON.stringify(selectedIDs),
        })
        .then(function (response) {
          Swal.fire({
            type: "success",
            title:
              selectedIDs.length == 1
                ? "Spender gelöscht!"
                : selectedIDs.length + " Spender gelöscht!",
            showConfirmButton: false,
            timer: 4000,
          });

          // Refresh table.
          self.$root.$emit("bv::refresh::table", "donorTable");
        })
        .catch(function (error) {
          Swal.fire({
            type: "error",
            title: i18n.main.error,
            text: error + ": " + error.response.data,
          });
        });
    },
    resetAndCloseMedicalModal() {
      this.editDataModalSaveDetailsIsInProgress = false;
      this.editDataModalSaveButtonIsDisabled = true;
      // this.editDataModalRowItem = JSON.parse(
      //   JSON.stringify(this.editDataModalData)
      // );
      this.editDataModalData = null;
      this.$bvModal.hide("editData");

      // // Refresh table.
      // this.$root.$emit("bv::refresh::table", "donorTable");

      // this.editDataModalRowItem = JSON.parse(JSON.stringify(row.item));
      // this.editDataModalData = JSON.parse(JSON.stringify(row.item));
    },
    getStatusName(statusNumber) {
      switch (statusNumber) {
        case 0:
          return "Offen";
        case 1:
          return "Warten auf Spender";
        case 2:
          return "Versandbereit für Labor";
        case 3:
          return "Warten auf Labor";
        case 4:
          return "Warten auf Übertragung in DoCCom";
        case 5:
          return "Übertragen in DoCCom";
        case 6:
          return "Aktiv";
        case 7:
          return "Warten auf Löschen in DoCCom";
        default:
          return "Alle, außer Aktiv";
      }
    },
  },
  components: {
    "vue-simple-context-menu": VueSimpleContextMenu,
    vueDropzone: vue2Dropzone,
    scanBarcode,
  },
};
</script>

<style scoped>
.button-area {
  margin-bottom: 4px;
}
.badge {
  cursor: default;
  font-size: 90%;
}
.lab-icon {
  padding-left: 3px;
}
.empty-row {
  display: block;
  text-align: center;
}
.medicalToggle {
  /* margin-left: 30px; */
}
.medical-radio-button-group {
  padding-bottom: 4px;
}
.column-separator-left {
  border-left: 1px solid #dfdfdf;
}
.medical-alert {
  margin: 6px 0 0 0;
}
.medicalContentBox {
  margin-bottom: 14px;
}
#approvalCheckboxesGroup {
  display: inline-flex;
}
.showMedicalDetailsButton,
.show-details-button .show-details-button-active {
  padding: 0 8px;
}
.show-details-button-container {
  width: 42px;
}
.inline-block {
  display: inline-block;
}
.preserveWhitespace {
  white-space: pre;
}
.textarea-with-validation {
  /* Corrects the position of the validation icon within the textarea. */
  background-position: top calc(0.4em + 0.1875rem) right calc(1.4em + 0.1875rem) !important;
}
</style>

<style>
body {
  overflow-y: scroll;
}
.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.04) !important;
}
.tooltip {
  opacity: 1 !important;
}
.show-details-button {
  display: none !important;
}
.editDataModalMainRow .row {
  margin-top: 5px !important;
}
tr {
  cursor: unset !important;
}
tr:hover .show-details-button,
.show-details-button-active {
  padding: 0 8px;
  display: unset !important;
}
.custom-checkbox {
  margin: 0 23px 0 23px;
  height: 37px;
  padding-top: 7px;
}
</style>