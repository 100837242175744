/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
require('./bootstrap');

import adminList from "./components/adminList.vue";
import donorRegistration from "./components/donorRegistration.vue";
import donorLogin from "./components/donorLogin.vue";
import BootstrapVue from "bootstrap-vue";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import Vue from 'vue';
import datePicker from 'vue-bootstrap-datetimepicker';
// You need a specific loader for CSS files
//import 'bootstrap/dist/css/bootstrap.css';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';

Vue.use(datePicker);

window.Swal = Swal;


import _ from "lodash";
window.Vue = require('vue');

Vue.prototype.trans = string => _.get(window.i18n, string);
Vue.use(BootstrapVue);

Vue.config.devtools = true;

Vue.filter("dateGerman", function (value) {
    if (!value) return "";
    value = moment(value).format("DD.MM.YYYY HH:mm");
    return value;
});

Vue.filter("dateGermanWithoutTime", function (value) {
    if (!value) return "";
    value = moment(value).format("DD.MM.YYYY");
    return value;
});

Vue.filter("dateTimeToTimeOnly", function (value) {
    if (!value) return "";
    let valueStr = moment(value).format("HH:mm");
    return valueStr;
});

window.app = new Vue({
    el: "#app",
    name: "root",
    //router,
    data: {},
    mounted() {
        window.vueMounted = true;
    },
    components: {
        "admin-list": adminList,
        "donorregistration-list": donorRegistration,
        "donorlogin-list": donorLogin,
    }
});
