<template>
  <b-form @submit="onSubmit">
    <b-container>
      <b-card v-if="!emailSended" class="mt-5" no-body style="border: none">
        <b-card-header style="background: #C9A000; color: white;">
          <h4>Spender Login</h4>
        </b-card-header>
        <b-card-body>
          <!-- input donor id -->
          <b-form-group
            id="input-group-donor_id"
            label="Spendernummer (DEHAN Nr.):"
            label-for="input-donor_id"
          >
            <b-form-input
              id="input-donor_id"
              v-model="form.donor_id"
              placeholder="Nur die Zahlen eingeben"
              required
            ></b-form-input>
          </b-form-group>
          <!-- input birthdate -->
          <b-form-group
            id="input-group-birthdate"
            label="Geburtsdatum:"
            label-for="input-birthdate"
          >
            <b-input-group date inline>
              <date-picker
                ref="datePicker"
                id="input-birthdate"
                v-model="calenderDate"
                :config="options"
                placeholder="TT.MM.JJJJ"
                required
              ></date-picker>
            </b-input-group>
          </b-form-group>
          <!-- Input captcha -->
          <b-form-group
            id="input-group-captcha"
            label="Sicherheitskontrolle:"
            label-for="input-captcha"
          >
            Bitte lösen Sie die folgende Aufgabe. Dies ist notwendig, um automatisierte Prozesse zu unterbinden.
            <div class="captcha">
              <img src="img/captcha-placeholder.jpg" alt="Captcha"/>
              <b-button id="captcha-refresh-button" :disabled="waitingForNewCaptcha" @click="getCaptcha()">
                <span v-if="!waitingForNewCaptcha">
                  <i class="fas fa-sync-alt"></i>
                </span>
                <span v-if="waitingForNewCaptcha">
                  <b-spinner small></b-spinner>
                </span>
              </b-button><br/>
            </div>
            <b-form-input
              id="input-captcha"
              v-model="form.captcha"
              placeholder="Geben Sie das Ergebnis hier ein"
              required
            ></b-form-input>
          </b-form-group>
          <!-- info message if record have no email -->
          <b-alert :show="!emailExist" variant="info">
            Um Ihre Daten ändern zu können muss eine E-Mail angegeben werden.
            <br />Die E-Mail wird beim Absenden direkt hinterlegt, um in Zukunft diesen Schritt zu überspringen.
          </b-alert>
          <!-- input email -->
          <b-form-group
            v-if="!emailExist"
            id="input-group-email"
            label="E-Mail:"
            label-for="input-email"
          >
            <b-form-input id="input-email" v-model="form.email" type="email" required></b-form-input>
          </b-form-group>
          <!-- error message -->
          <b-form-group class="mt-2" v-model="loginFail">
            <b-alert
              :show="loginFail"
              variant="danger"
              v-for="value in errors"
              :key="value.id"
            >{{value[0]}}</b-alert>
          </b-form-group>
          <!-- submit button -->
          <b-button :disabled="checkForMail" type="submit" variant="primary">
            Abschicken
            <span v-if="checkForMail">
              <b-spinner small></b-spinner>
            </span>
          </b-button>
        </b-card-body>
      </b-card>
      <!-- success message -->
      <b-card v-if="emailSended" class="mt-5" header-tag="header">
        <h4 slot="header" class="mb-0"></h4>
        <h2 style="text-align:center">Ihnen wurde eine E-Mail zur Verifizierung geschickt.</h2>
      </b-card>
    </b-container>
  </b-form>
</template>
<script>

export default {
  data() {
    return {
      form: {
        email: null,
        birthdate: null,
        donor_id: null,
        key: null,
      },
      calenderShow: false,
      calenderDate: null,
      errors: [],
      emailSended: false,
      checkForMail: false,
      loginFail: false,
      emailExist: true,
      // Datepicker settings
      options: {
        locale: "de",
        format: "DD.MM.YYYY",
        useCurrent: false,
        showClear: true,
        showClose: true,
        defaultDate: moment().subtract(17, "year"),
        widgetPositioning: {
          horizontal: "right",
          vertical: "auto"
        }
      },
      waitingForNewCaptcha: false
    };
  },
  mounted() {
    this.getCaptcha()
  },
  methods: {
    moment: function() {
      return moment();
    },
    onSubmit(evt) {
      // change date format for DB
      this.form.birthdate = moment(this.calenderDate, "DD.MM.YYYY")
        .utc("+0200")
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
      evt.preventDefault();
      // Disables submit button
      this.checkForMail = true;

      axios
        .post("/api/donorLogin", this.form)
        .then(res => {
          if (res.data === false) {
            console.log(res);
            this.emailExist = false;
            // Enables submit button
            this.checkForMail = false;
          } else {
            this.emailSended = true;
          }
        })
        .catch(err => {
          this.checkForMail = false;
          this.loginFail = true;
          console.log(err);

          if (err.response.data.errors != undefined) {
            if (err.response.data.errors.donor_id) {
              err.response.data.errors.donor_id[0] =
                'Die angegebene "Spendernummer" ist nicht vorhanden';
            }
            if (err.response.data.errors.birthdate) {
              err.response.data.errors.birthdate[0] =
                'Das angegebene "Geburtsdatum" ist nicht vorhanden';
            }
            this.errors = err.response.data.errors;
          } else {
            this.errors = {
              error: [err.response.data]
            };
          }
        });
    },
    getCaptcha() {
      this.waitingForNewCaptcha = true
      document.querySelector("div.captcha img").src = "img/captcha-placeholder.jpg"
      axios.get('/captcha/api/math').then(resp => {
        var key = resp.data.key
        var img = resp.data.img
        document.querySelector("div.captcha img").src = img
        this.waitingForNewCaptcha = false
        this.form.key = key
      });
    }
  }
};
</script>

<style>
  div.captcha {
    margin-top: 5px;
    margin-bottom: 5px;
    display: flex;
  }
  div.captcha img {
    min-width: 122px;
    min-height: 38px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    margin-right: 5px;
  }
  #captcha-refresh-button {
    width: 42px;
    height: 38px;
  }
  #captcha-refresh-button .spinner-border-sm {
    margin-top: 4px;
  }
</style>