<template>
  <b-container>
    <div v-for="(dynamicField, index) in fields" :key="index">
      <b-row v-if="Array.isArray(dynamicField)">
        <b-col
          :class="field.class?field.class:''"
          :cols="field.cols?field.cols:''"
          v-for="field in dynamicField"
          :key="field.key"
        >
          <slot :name="'field_'+field.key" :fieldProperties="field" :fieldData="data">
            <form-field
              :field="field"
              :data="data"
              :validation="validations[field.key]?validations[field.key]:{}"
            ></form-field>
          </slot>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col :class="dynamicField.class?dynamicField.class:''">
          <slot :name="'field_'+dynamicField.key" :fieldProperties="dynamicField" :fieldData="data">
            <form-field
              :field="dynamicField"
              :data="data"
              :validation="validations[dynamicField.key]?validations[dynamicField.key]:{}"
            ></form-field>
          </slot>
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import FormField from "./FormRow";
import { BContainer, BRow, BCol } from "bootstrap-vue";

export default {
  components: {
    "b-container": BContainer,
    "b-row": BRow,
    "b-col": BCol,
    "form-field": FormField
  },
  props: {
    fields: {
      required: true,
      type: Array
    },
    data: {
      required: true,
      type: Object,
      default: {}
    },
    validations: {
      required: false,
      type: Object,
      default: {}
    }
  }
};
</script>
