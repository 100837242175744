<template>
  <b-form-group
    v-if="field.hasOwnProperty('datatype')"
    v-show="!field.hidden && dependant_value"
    :label="field.title"
  >
    <div v-if="field.datatype == 'checkbox'">
      <b-form-checkbox-group
        v-model="data[field.key]"
        :options="field.options"
        :stacked="field.stacked"
        :disabled="field.readonly"
      ></b-form-checkbox-group>
    </div>
    <div v-else-if="field.datatype == 'radio'">
      <b-form-radio-group
        v-model="data[field.key]"
        :options="field.options"
        :stacked="field.stacked"
        :disabled="field.readonly"
      ></b-form-radio-group>
    </div>
    <div v-else-if="field.datatype == 'bool'">
      <b-form-radio-group
        :disabled="field.readonly"
        :stacked="field.stacked"
        v-model="data[field.key]"
      >
        <b-form-radio :value="true">{{field.bool_phrases_yes?field.bool_phrases_yes:'Yes'}}</b-form-radio>
        <b-form-radio :value="false">{{field.bool_phrases_no?field.bool_phrases_no:'No'}}</b-form-radio>
      </b-form-radio-group>
    </div>
    <div v-else-if="field.datatype == 'time' ||field.datatype == 'datetime' ">
      <b-form-input class="form-control" v-if="field.readonly" v-model="data[field.key]" readonly></b-form-input>
      <datetime
        v-else
        :type="field.datatype"
        :minute-step="field.datetime_min_step?field.datetime_min_step:5"
        :auto="field.datetime_autoSubmit?field.datetime_autoSubmit:false"
        :phrases="{
              ok: field.datetime_phrases_ok?field.datetime_phrases_ok:'OK',
              cancel: field.datetime_phrases_cancel?field.datetime_phrases_cancel:'Cancel'
             }"
        v-model="data[field.key]"
      ></datetime>
    </div>
    <div v-else-if="field.datatype == 'select'">
      <div>
        <b-form-select
          :disabled="field.readonly"
          :required="validation.required"
          :options="field.options"
          v-model="data[field.key]"
        ></b-form-select>
      </div>
    </div>
    <div v-else-if="field.datatype == 'textarea'">
      <div>
        <b-form-textarea
          :placeholder="field.placeholder"
          :required="validation.required"
          :rows="field.textarea_rows?field.textarea_rows:2"
          v-model="data[field.key]"
          :readonly="field.readonly"
        ></b-form-textarea>
      </div>
    </div>
    <div v-else>
      <b-form-input
        :type="field.datatype?field.datatype:'text'"
        :placeholder="field.placeholder?field.placeholder:''"
        :required="validation.required"
        v-model="data[field.key]"
        :step="field.step?field.step:1"
        :readonly="field.readonly"
      />
    </div>
    <small
      v-if="(!validation.hasOwnProperty('required') || !validation.required)&&field.datatype!='bool'"
      class="form-text text-muted"
    >{{field.optional_phrases?field.optional_phrases:'Optional'}}</small>
  </b-form-group>
  <b-form-group v-else>
    <b-alert show variant="warning">No datatype for '{{field.title}}' found in fields</b-alert>
  </b-form-group>
</template>

<script>
import { Datetime } from "vue-datetime";

import {
  BFormInput,
  BFormGroup,
  BFormRadio,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BFormSelect,
  BFormTextarea,
  BAlert
} from "bootstrap-vue";
export default {
  components: {
    datetime: Datetime,
    "b-form-input": BFormInput,
    "b-form-group": BFormGroup,
    "b-form-radio": BFormRadio,
    "b-form-radio-group": BFormRadioGroup,
    "b-form-checkbox-group": BFormCheckboxGroup,
    "b-form-select": BFormSelect,
    "b-form-textarea": BFormTextarea,
    "b-alert": BAlert
  },
  props: {
    field: {
      required: true,
      type: Object
    },
    data: {
      required: true,
      type: Object,
      default: {}
    },
    validation: {
      required: false,
      type: Object,
      default: {}
    }
  },

  computed: {
    dependant_value() {
      if (this.field.dependence_on) {
        let dependant_field = this.field.dependence_on.split(":")[0];
        let show_value = this.field.dependence_on.split(":")[1];
        if (show_value) {
          return "" + this.data[dependant_field] == show_value;
        } else {
          return (
            this.data[this.field.dependence_on] == false ||
            (this.data[this.field.dependence_on] != undefined &&
              this.data[this.field.dependence_on] != "")
          );
        }
      }

      return true;
    }
  }
};
</script>