<template>
  <b-container>
    <b-form @submit="onSubmit" v-if="show" :class="formStyle">
      <b-card no-body class="my-2" style="border: none">
        <b-card-header style="background: #c9a000; color: white">
          <h2 v-if="simple">Spenderdaten aktualisieren</h2>
          <h3 v-if="!simple && !blank">Spender Registrierung</h3>
          <h3 v-if="blank">
            Spender Registrierung
            {{
              blank.includes("-")
                ? "(Barcode-Nr. " + blank + ")"
                : "(Spendernummer " + blank + ")"
            }}
          </h3>
        </b-card-header>
        <b-card-body>
          <vue-bootstrap-form-generator
            :data="form"
            :fields="fields"
            :validations="validations"
          >
            <template #field_calenderDate="{ fieldProperties, fieldData }">
              <b-form-group>
                <date-picker
                  v-model="fieldData[fieldProperties.key]"
                  :config="options"
                  :placeholder="fieldProperties.placeholder"
                  required
                ></date-picker>
              </b-form-group>
            </template>

            <template #field_donation="{ fieldProperties, fieldData }">
              <b-input-group
                append="€"
                v-show="
                  fieldData['donationQuestion'] == true || form.device_id == 1
                "
              >
                <b-input
                  type="number"
                  pattern="[0-9]+([\.,][0-9]+)?"
                  step="0.01"
                  class="text-right money-input"
                  v-model="fieldData[fieldProperties.key]"
                ></b-input>
              </b-input-group>
            </template>
          </vue-bootstrap-form-generator>
          <b-container>
            <b-row v-if="!showChangeForm">
              <b-col cols="12">
                <h3 v-if="!blank">Datenschutzrechtliche Einwilligung</h3>
              </b-col>
            </b-row>
            <b-row v-if="!showChangeForm">
              <!-- terms and use question -->
              <b-col cols="12" v-if="form.device_id != 1">
                <p>
                  Ihre Daten werden ausschließlich für die Suche nach passenden
                  Stammzellspendern und zur Kontaktaufnahme mit Ihnen verwendet.
                  Alle Daten sind auf speziell geschützten Servern abgelegt.
                  Hiermit willige ich in die Verarbeitung meiner
                  personenbezogenen Daten zum Zwecke der Anforderung und dem
                  Versand eines Typisierungs-Set sowie damit zusammenhängender
                  Rückfragen ein.
                </p>
                <b-form-checkbox
                  id="terms-and-use"
                  name="terms-and-use"
                  value="accepted"
                  unchecked-value="not_accepted"
                  required
                >
                  Die Hinweise zum
                  <a href="https://nkr.life/datenschutz/" target="_blank"
                    >Datenschutz</a
                  >
                  habe ich zur Kenntnis genommen.
                </b-form-checkbox>
              </b-col>
            </b-row>
            <br>
            <!-- Input captcha -->
            <b-row :hidden="blank || showChangeForm">
              <b-col cols="12">
                <b-form-group
                  id="input-group-captcha"
                  label="Sicherheitskontrolle:"
                  label-for="input-captcha"
                >
                Bitte lösen Sie die folgende Aufgabe. Dies ist notwendig, um automatisierte Prozesse zu unterbinden.
                  <div class="captcha">
                    <img src="img/captcha-placeholder.jpg" alt="Captcha"/>
                    <b-button id="captcha-refresh-button" :disabled="waitingForNewCaptcha" @click="getCaptcha()">
                      <span v-if="!waitingForNewCaptcha">
                        <i class="fas fa-sync-alt"></i>
                      </span>
                      <span v-if="waitingForNewCaptcha">
                        <b-spinner small></b-spinner>
                      </span>
                    </b-button><br/>
                  </div>
                  <b-form-input
                    id="input-captcha"
                    v-model="form.captcha"
                    placeholder="Geben Sie das Ergebnis hier ein"
                    :required="!blank && !showChangeForm"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <!-- Error messages -->
              <b-col cols="12" lg="12">
                <b-form-group v-model="showTextarea.error">
                  <b-alert
                    :show="showTextarea.error"
                    variant="danger"
                    v-for="value in errors"
                    :key="value.id"
                    >{{ value[0] }}</b-alert
                  >
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="!showChangeForm">
              <!-- submit button -->
              <b-col cols="12">
                <b-button
                  :disabled="processing"
                  type="submit"
                  variant="primary"
                  >Registrieren
                  <span v-if="processing">
                    <b-spinner small></b-spinner>
                  </span>
                </b-button>
              </b-col>
            </b-row>
            <b-row v-if="showChangeForm">
              <!-- info message -->
              <b-col cols="12" lg="12">
                <b-alert show variant="info">
                  Bitte füllen Sie alle Felder aus.
                  <br />Wird eines der optionalen Felder leer gelassen, wird
                  dieses aus der Datenbank gelöscht.
                </b-alert>
              </b-col>
            </b-row>
            <!-- Submit changes button -->
            <b-row v-if="showChangeForm">
              <b-col cols="12" class="mb-2" inline>
                <b-button
                  :disabled="processing"
                  type="submit"
                  variant="primary"
                  >Änderung speichern
                  <span v-if="processing">
                    <b-spinner small></b-spinner>
                  </span>
                </b-button>
                <!-- Cancel button -->
                <b-button @click="cancel" variant="danger" style="width: 150px"
                  >Abbrechen</b-button
                >
              </b-col>
            </b-row>
          </b-container>
        </b-card-body>
      </b-card>
    </b-form>
    <!-- Success message on data changes -->
    <b-container v-if="changeSuccess">
      <b-col class="mt-5">
        <b-alert show variant="success">
          <h3 style="text-align: center">
            Ihre Daten wurden erfolgreich geändert.
          </h3>
        </b-alert>
      </b-col>
    </b-container>
    <!-- Success message on registration -->
    <b-container v-if="registerSuccess">
      <b-col class="mt-5">
        <b-alert show variant="success">
          <h3 style="text-align: center">
            Ihre Registrierung war erfolgreich.
          </h3>
        </b-alert>
      </b-col>
    </b-container>
  </b-container>
</template>



<script>
import VueBootstrapFormGenerator from "./VueBootstrapFormGenerator";
export default {
  props: {
    blank: {
      type: String,
      required: false,
      default: null,
    },
    donor: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
    simple: {
      default: false,
    },
  },
  components: { VueBootstrapFormGenerator },
  data() {
    return {
      fields: [
        {
          placeholder: "Geschlecht",
          key: "sex",
          datatype: "radio",
          options: [
            { value: "w", text: "Frau" },
            { value: "m", text: "Herr" },
          ],
        },
        [
          {
            placeholder: "Vorname",
            key: "firstname",
            datatype: "text",
          },

          {
            placeholder: "Nachname",
            key: "lastname",
            datatype: "text",
          },
        ],

        [
          {
            placeholder: "Straße",
            key: "street",
            datatype: "text",
            cols: 8,
          },

          {
            placeholder: "Hausnr.",
            key: "street_number",
            datatype: "text",
            cols: 4,
          },
        ],
        [
          {
            placeholder: "Wohnort",
            key: "place",
            datatype: "text",
            cols: 8,
          },
          {
            placeholder: "Postleitzahl",
            key: "zip_code",
            datatype: "text",
            cols: 4,
          },
        ],
        [
          {
            placeholder: "Telefon privat",
            key: "phone_private",
            datatype: "text",
          },

          {
            placeholder: "Telefon mobil",
            key: "phone_mobile",
            datatype: "text",
          },
        ],
        [
          {
            placeholder: "Telefon dienstlich",
            key: "phone_day",
            datatype: "text",
          },

          {
            placeholder: "E-Mail",
            key: "email",
            datatype: "text",
          },
        ],
      ],
      validations: {
        firstname: {
          required: true,
        },
        lastname: {
          required: true,
        },
        birthdate: {
          required: true,
        },
        sex: {
          required: true,
        },
        street: {
          required: true,
        },
        street_number: {
          required: true,
        },
        zip_code: {
          required: true,
        },
        place: {
          required: true,
        },
        email: {
          required: true,
        },
        major_medical_results_affirmed: {
          required: true,
        },
        major_medical_results: {
          required: false,
        },
        medications_affirmed: {
          required: true,
        },
        medications: {
          required: false,
        },
        donationQuestion: {
          required: true,
        },
        phone_private: {
          required: false,
        },
      },
      form: {
        email: null,
        lastname: null,
        firstname: null,
        birthdate: null,
        sex: null,
        street: null,
        street_number: null,
        zip_code: null,
        place: null,
        phone_private: null,
        phone_day: null,
        phone_mobile: null,
        major_medical_results: null,
        major_medical_results_affirmed: null,
        medications: null,
        medications_affirmed: null,
        device_id: 0,
        barcode_id: this.blank,
        donation: null,
        two_factor_token: null,
        donor_approved: 1,
      },
      showTextarea: {
        error: "",
      },
      // settings for datepicker
      options: {
        locale: "de",
        format: "DD.MM.YYYY",
        useCurrent: false,
        showClear: true,
        showClose: true,
        defaultDate: moment().subtract(17, "year"),
        widgetPositioning: {
          horizontal: "right",
          vertical: "auto",
        },
      },
      calenderDate: null,
      errors: [],
      show: true,
      donorId: this.donor.id,
      changeSuccess: false,
      showChangeForm: false,
      registerSuccess: false,
      formStyle: "registration",
      processing: false,
      waitingForNewCaptcha: false,
    };
  },
  computed: {
    changeToRequired() {
      if (
        this.form.major_medical_results_affirmed == 1 ||
        this.form.medications_affirmed == 1
      ) {
        this.validations.phone_private.required = true;
        if (!this.blank) {
          this.form.donor_approved = 0;
        }
      } else if (
        this.form.major_medical_results_affirmed == 0 &&
        this.form.medications_affirmed == 0
      ) {
        this.validations.phone_private.required = false;
        if (!this.blank) {
          this.form.donor_approved = 1;
        }
      }
      // error if textarea is required and not visible, required has to be false if invisible
      if (this.form.major_medical_results_affirmed == 1) {
        this.validations.major_medical_results.required = true;
      } else {
        this.validations.major_medical_results.required = false;
      }
      //..same for this one
      if (this.form.medications_affirmed == 1) {
        this.validations.medications.required = true;
      } else {
        this.validations.medications.required = false;
      }
    },
  },
  watch: {
    changeToRequired() {
      //console.log(this.from.major_medical_results_affirmed);
    },
  },
  mounted() {
    if (!this.simple) {
      this.fields.splice(3, 0, {
        key: "calenderDate",
        datatype: "datetime",
        placeholder: "Geburtsdatum (TT.MM.JJJJ)",
      });
      this.fields.push(
        {
          title: i18n.main.medical_results_question,
          key: "major_medical_results_affirmed",
          datatype: "radio",
          stacked: false,
          options: [
            { value: "1", text: "Ja" },
            { value: "0", text: "Nein" },
          ],
        },
        {
          title: "Welche?",
          key: "major_medical_results",
          datatype: "textarea",
          dependence_on: "major_medical_results_affirmed:1",
        },
        {
          title: "Nehmen Sie dauerhaft Medikamente?",
          key: "medications_affirmed",
          datatype: "radio",
          stacked: false,
          options: [
            { value: "1", text: "Ja" },
            { value: "0", text: "Nein" },
          ],
        },
        {
          title: "Welche?",
          key: "medications",
          datatype: "textarea",
          dependence_on: "medications_affirmed:1",
          optional_phrases: " ",
        },
        {
          title:
            "Über Ihre Unterstützung in Form einer Geldspende würden wir uns sehr freuen! \nSpenden?",
          key: "donationQuestion",
          class: "preserveWhitespace",
          datatype: "radio",
          stacked: false,
          options: [
            { value: true, text: "Ja" },
            { value: false, text: "Nein" },
          ],
          dependence_on: "device_id:0",
        },
        {
          key: "donation",
          datatype: "number",
          cols: 4,
        }
      );
    } else {
      this.formStyle = "blank";
    }
    // Check if donor does registration..
    if (!this.blank) {
      this.form.barcode_id = 0;
    } else {
      // ..or a user
      this.form.device_id = 1;
      this.formStyle = "blank";
      this.validations.email.required = false;
      this.fields.push({
        title: "Freigeben?",
        key: "donor_approved",
        datatype: "radio",
        stacked: false,
        optional_phrases: " ",
        options: [
          { value: "1", text: "Ja" },
          { value: "0", text: "Nein" },
        ],
        class: "mt-3",
      });
    }

    // if true show data change container and hide register container
    if (this.donor.email) {
      this.fields.shift();
      this.showChangeForm = true;
      this.form.email = this.donor.email;
      this.form.lastname = this.donor.lastname;
      this.form.firstname = this.donor.firstname;
      this.form.street = this.donor.street;
      this.form.street_number = this.donor.street_number;
      this.form.zip_code = this.donor.zip_code;
      this.form.place = this.donor.place;
      this.form.phone_private = this.donor.phone_private;
      this.form.phone_day = this.donor.phone_day;
      this.form.phone_mobile = this.donor.phone_mobile;
      this.form.two_factor_token = this.donor.two_factor_token;
    }

    this.getCaptcha()
  },
  methods: {
    moment: function () {
      return moment();
    },
    onSubmit(evt) {
      // Disables submit button
      this.processing = true;

      // Reformat donation (required for Internet Explorer)
      if (this.form.donation != null) {
        this.form.donation = this.form.donation.replace(",", ".");
      }

      evt.preventDefault();
      // Registration
      if (!this.showChangeForm) {
        // change date format for DB
        this.form.birthdate = moment(this.form.calenderDate, "DD.MM.YYYY")
          .utc("+0200")
          .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
        let self = this;
        axios
          .post(this.blank ? "/api/donor" : "/api/donorRegistration", self.form)
          .then((res) => {
            // Enables submit button
            this.processing = false;

            self.showTextarea.error = false;
            if (self.form.device_id == 1) {
              self.$emit("registered", "true");
            } else {
              self.show = false;
              self.registerSuccess = true;

              setTimeout(function () {
                window.location.href = "https://www.nkr.life/danke-registrierung/";
              }, 1000);
            }
          })
          .catch((err) => {
            // Enables submit button
            this.processing = false;
            self.showTextarea.error = true;
            if (err.response.data.errors != undefined) {
              self.errors = err.response.data.errors;
            } else {
              self.errors = {
                error: [err.response.data],
              };
            }
          });
      } else {
        // Data changes
        evt.preventDefault();
        axios
          .put("/api/donor/" + this.donorId, this.form)
          .then((res) => {
            this.showTextarea.error = false;
            this.show = false;
            this.changeSuccess = true;
            setTimeout(function () {
              window.location.href = "https://www.nkr.life/danke-datenaenderung/";
              }, 1000);

          })
          .catch((err) => {
            // Enables submit button
            this.processing = false;
            this.showTextarea.error = true;
            if (err.response.data.errors != undefined) {
              this.errors = err.response.data.errors;
            } else {
              this.errors = {
                error: [err.response.data],
              };
            }
          });
      }
    },
    cancel() {
      window.location.href = "/";
    },
    getCaptcha() {
      this.waitingForNewCaptcha = true
      document.querySelector("div.captcha img").src = "img/captcha-placeholder.jpg"
      axios.get('/captcha/api/math').then(resp => {
        var key = resp.data.key
        var img = resp.data.img
        document.querySelector("div.captcha img").src = img
        this.waitingForNewCaptcha = false
        this.form.key = key
      });
    }
  },
};
</script>

<style>
.blank {
  color: black;
}
div.preserveWhitespace > fieldset > legend {
  white-space: pre;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}
.registration input.money-input {
  border-radius: 0 !important;
}
div.captcha {
    margin-top: 5px;
    margin-bottom: 5px;
    display: flex;
}
div.captcha img {
  min-width: 122px;
  min-height: 38px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  margin-right: 5px;
}
#captcha-refresh-button {
  width: 42px;
  height: 38px;
}
#captcha-refresh-button .spinner-border-sm {
  margin-top: 4px;
}
</style>