<template>
  <div>
    <b-modal
      id="scanBarcode"
      ref="scanBarcode"
      :title="trans('main.scan_barcode')"
      @shown="focusBarcodeInput"
      hide-footer
      no-stacking
    >
      <!-- Barcode text field -->
      <b-input-group>
        <b-form-input
          ref="barcodeInput"
          v-model="barcode"
          type="text"
          :placeholder="trans('main.scan_barcode')"
          @keydown.enter="getDonor"
        ></b-form-input>
        <b-input-group-append>
          <b-button
            variant="primary"
            :disabled="barcode == ''"
            @click="getDonor"
          >{{ trans('main.to_search') }}</b-button>
        </b-input-group-append>
      </b-input-group>
      <br />

      <!-- Show donor details -->
      <b-table
        class="donor-data"
        cols="8"
        stacked
        small
        show-empty
        :fields="fields"
        :items="items"
        v-if="items.length > 0"
      >
        <!-- Barcode -->
        <template
          slot="cell(barcode)"
          slot-scope="data"
        >{{ data.item.device_id }}-{{ data.item.barcode_id }}</template>

        <!-- Sent date -->
        <template
          slot="cell(sent_date)"
          slot-scope="data"
        >{{ data.item.sent_date != null ? (("0" + new Date(data.item.sent_date).getDate()).slice(-2) + "." + ("0" + (new Date(data.item.sent_date).getMonth()+1)).slice(-2) + "." + new Date(data.item.sent_date).getFullYear()) : "" }}</template>

        <!-- Template for the visualization of the different statuses -->
        <template slot="cell(status)" slot-scope="row">
          <b-badge v-if="row.value == 0" variant="light">{{trans('main.open')}}</b-badge>
          <b-badge v-if="row.value == 1" variant="secondary">{{trans('main.waiting_for_donor')}}</b-badge>
          <b-badge
            v-if="row.value == 2"
            variant="primary"
          >{{trans('main.ready_for_sending_to_lab')}}</b-badge>
          <b-badge v-if="row.value == 3" variant="info">{{trans('main.waiting_for_lab')}}</b-badge>
          <b-badge
            v-if="row.value == 4"
            variant="warning"
          >{{trans('main.waiting_for_transferring_to_doccom')}}</b-badge>
          <b-badge
            v-if="row.value == 5"
            style="color: black; background-color: #7ff3ad;"
          >{{trans('main.transferred_in_doccom')}}</b-badge>
          <b-badge v-if="row.value == 6" variant="success">{{trans('main.active')}}</b-badge>
          <b-badge
            v-if="row.value == 7"
            variant="danger"
          >{{trans('main.waiting_for_deletion_in_doccom')}}</b-badge>
          <span v-if="row.item.sample_was_not_evaluable">
            <i
              :id="'labIconOnScanBarcode' + row.item.id"
              class="fas fa-vial fa-lg text-danger lab-icon"
            ></i>
            <b-tooltip
              class="danger-tooltip"
              :target="'labIconOnScanBarcode' + row.item.id"
              variant="danger"
              triggers="hover"
            >Letzte Probe war nicht auswertbar</b-tooltip>
          </span>
        </template>
      </b-table>

      <!-- Blanko register button -->
      <div v-else-if="!blankoButtonHidden" class="text-center">
        <br />
        <b-button variant="primary" @click="donorRegisteredShow">
          {{ trans('main.register_donor_from_blanko_campaign') }}&nbsp;
          <b-badge variant="light">
            <i class="fas fa-user-plus"></i>
          </b-badge>
        </b-button>
        <br />
        <br />
      </div>

      <!-- No donor found message -->
      <div v-else-if="!notFoundMessageHidden" class="text-center">
        <br />
        {{ trans('main.no_donor_found') + '.' }}
        <br />
        <br />
      </div>

      <!-- Transition Button -->
      <div id="transitionDiv" class="text-center" :hidden="items.length == 0">
        <br />
        <b-button
          v-if="currentPossibleTransition.name != ''"
          variant="primary"
          @click="runTransition(items[0].id, currentPossibleTransition.value)"
        >
          {{ currentPossibleTransition.name }}&nbsp;
          <b-badge variant="light">
            <i class="fas fa-arrow-right"></i>
          </b-badge>
        </b-button>
        <span v-else>{{ trans('main.currently_no_status_transition_possible') + "." }}</span>
        <br />
        <br />
      </div>
    </b-modal>

    <!-- Donor registration modal -->
    <b-modal ref="donorRegistrationModal" size="xl" hide-footer>
      <donorRegistration :blank="barcode" @registered="donorRegisteredHide"></donorRegistration>
    </b-modal>
  </div>
</template>

<script>
import donorRegistration from "./donorRegistration";
export default {
  data() {
    return {
      barcode: "",
      fields: [
        { key: "barcode", label: "Barcode-Nr." },
        {
          key: "donor_id",
          label: "Spendernummer",
          sortable: true
        },
        {
          key: "firstname",
          label: i18n.main.firstname,
          sortable: true
        },
        {
          key: "lastname",
          label: i18n.main.lastname,
          sortable: true
        },
        {
          key: "status",
          label: i18n.main.status,
          sortable: true
        },
        {
          key: "sent_count",
          label: i18n.main.sent_count,
          sortable: true
        },
        {
          key: "sent_date",
          label: i18n.main.sent_date,
          sortable: true
        }
      ],
      items: [],
      notFoundMessageHidden: true,
      blankoButtonHidden: true,
      currentPossibleTransition: {},
      donorRegistrationResult: false
    };
  },
  methods: {
    show() {
      this.barcode = "";
      this.items = [];
      this.notFoundMessageHidden = true;
      this.blankoButtonHidden = true;
      this.currentPossibleTransition = {
        name: "",
        value: ""
      };
      this.$refs["scanBarcode"].show();
    },
    hide() {
      this.$refs["scanBarcode"].hide();
    },
    focusBarcodeInput() {
      this.$refs["barcodeInput"].focus();
    },
    getDonor() {
      if (this.barcode == "") {
        return;
      }

      let self = this;
      axios
        .get("/api/barcode/" + this.barcode)
        .then(response => {
          self.items = [];
          self.blankoButtonHidden = true;
          if (response.data === "valid blanko barcode") {
            self.blankoButtonHidden = false;
          } else if (self.barcode == "" && response.data.data != undefined) {
            self.notFoundMessageHidden = false;
          } else if (JSON.stringify(response.data) === JSON.stringify({})) {
            self.notFoundMessageHidden = false;
          } else {
            self.items.push(response.data);
            self.getCurrentPossibleTransition();
          }
        })
        .catch(function(error) {
          console.log(error);
          Swal.fire({
            type: "error",
            title: i18n.main.error,
            text: error + ": " + error.response.data
          });
        });
      this.$refs["barcodeInput"].select();
    },
    getCurrentPossibleTransition() {
      // Define Status enum.
      var Status = {
        0: "open",
        1: "waitingForDonor",
        2: "readyForSend",
        3: "waitingForLab",
        4: "waitingForTransfer",
        5: "transferred"
      };

      var currentStatus = this.items[0].status;
      var currentDonorRequiresARelease = this.doesThisItemRequireARelease(
        this.items[0]
      );

      switch (Status[currentStatus]) {
        case "open":
          if (!currentDonorRequiresARelease) {
            this.currentPossibleTransition = {
              name: i18n.main.sent_to_donor,
              value: "sentToDonor"
            };
          } else {
            this.currentPossibleTransition = {
              name: "",
              value: ""
            };
          }
          break;
        case "readyForSend":
          this.currentPossibleTransition = {
            name: i18n.main.sent_to_lab,
            value: "sentToLab"
          };
          break;
        case "waitingForDonor":
          this.currentPossibleTransition = {
            name: i18n.main.agreement_and_test_received,
            value: "agreementAndTestReceived"
          };
          break;
        default:
          this.currentPossibleTransition = {
            name: "",
            value: ""
          };
      }
    },
    runTransition(selectedIDs, transitionName) {
      axios
        .get("/runTransition", {
          params: {
            selectedIDs: JSON.stringify(selectedIDs),
            transition: transitionName
          }
        })
        .then(response => {
          // Refresh table.
          this.$root.$emit("bv::refresh::table", "donorTable");

          // Hide scan barcode modal.
          //this.hide();

          // Reset scan barcode modal.
          this.show();
          this.focusBarcodeInput();
        })
        .catch(function(error) {
          Swal.fire({
            type: "error",
            title: i18n.main.error,
            text: error + ": " + error.response.data
          });
        });
    },
    donorRegisteredShow() {
      this.$refs["donorRegistrationModal"].show();
    },
    donorRegisteredHide() {
      this.$refs["donorRegistrationModal"].hide();
      this.$root.$emit("bv::refresh::table", "donorTable");
      this.$emit("donorRegistered");
    },
    doesThisItemRequireARelease(item) {
      // Check if release for major medical results is required.
      if (item.major_medical_results_affirmed == 1) {
        if (
          item.major_medical_results == null ||
          item.major_medical_results.trim() == ""
        ) {
          return true;
        }
      }

      // Check if release for medications is required.
      if (item.medications_affirmed == 1) {
        if (item.medications == null || item.medications.trim() == "") {
          return true;
        }
      }

      return false;
    }
  },
  components: {
    donorRegistration
  }
};
</script>

<style scoped>
.donor-data {
  border-bottom: 3px solid #dee2e6;
}
.badge {
  font-size: 90%;
}
.empty-row {
  display: block;
  text-align: center;
}
</style>
